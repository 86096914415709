<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template #item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 d-flex">
        <span v-if="record._id">
          {{ "CWE-" + record.id + ": " + record.name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-divider />
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12" md="4">
            <div>{{ $t("Abstraction") }}</div>
            <div class="text-h3">{{ record.abstraction }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Structure") }}</div>
            <div class="text-h3">{{ record.structure }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Status") }}</div>
            <div class="text-h3">{{ record.status }}</div>
          </v-col>
          <v-col cols="12" class="mt-2">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Description") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  {{ record.description }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Extended description") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  {{ record.extended_description }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.alternate_terms">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Alternate Terms") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.alternate_terms.alternate_term" dense>
                    <template #default>
                      <tbody>
                        <tr v-for="(index, i) in record.alternate_terms.alternate_term" :key="'at' + i">
                          <td>{{ index.term }}</td>
                          <td>{{ index.description }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.related_weaknesses">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Relationships") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.related_weaknesses.related_weakness" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Nature") }}</th>
                          <th class="text-left">{{ $t("CWE ID") }}</th>
                          <th class="text-left">{{ $t("ID") }}</th>
                          <th class="text-left">{{ $t("Ordinal") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.related_weaknesses.related_weakness" :key="'ww' + i">
                          <td>{{ index.nature }}</td>
                          <td>
                            <router-link :to="'/cwe/' + index.cwe_id">
                              {{ index.cwe_id }}
                            </router-link>
                          </td>
                          <td>{{ index.view_id }}</td>
                          <td>{{ index.ordinal }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.background_details">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Background details") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <div v-for="(itm, ind) in record.background_details.background_detail" :key="'bd' + ind">
                    {{ itm }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.modes_of_introduction">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Modes of introduction") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.modes_of_introduction.introduction" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Phase") }}</th>
                          <th class="text-left">{{ $t("Note") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.modes_of_introduction.introduction" :key="'mi' + i">
                          <td>{{ index.phase }}</td>
                          <td>{{ index.note }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.applicable_platforms">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Applicable platforms") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 px-0 ">
                  <v-row dense>
                    <v-col v-if="record.applicable_platforms.language" cols="12">
                      <v-subheader v-if="record.applicable_platforms.language.length > 0">
                        {{ $t("Languages") }}
                      </v-subheader>
                      <div v-for="(itm, ind) in record.applicable_platforms.language" :key="'lang' + ind">
                        {{ itm.name + itm.class + " (" + itm.prevalence + ")" }}
                      </div>
                    </v-col>
                    <v-col v-if="record.applicable_platforms.architecture" cols="12">
                      <v-subheader v-if="record.applicable_platforms.architecture.length > 0">
                        {{ $t("Architecture") }}
                      </v-subheader>
                      <div v-for="(itm, ind) in record.applicable_platforms.architecture" :key="'arh' + ind">
                        {{ itm.name + itm.class + " (" + itm.prevalence + ")" }}
                      </div>
                    </v-col>
                    <v-col v-if="record.applicable_platforms.operating_system" cols="12">
                      <v-subheader v-if="record.applicable_platforms.operating_system.length > 0">
                        {{ $t("Operating system") }}
                      </v-subheader>
                      <div v-for="(itm, ind) in record.applicable_platforms.operating_system" :key="'os' + ind">
                        {{ itm.name + itm.class + " (" + itm.prevalence + ")" }}
                      </div>
                    </v-col>
                    <v-col v-if="record.applicable_platforms.technology" cols="12">
                      <v-subheader v-if="record.applicable_platforms.technology.length > 0">
                        {{ $t("Technology") }}
                      </v-subheader>
                      <div v-for="(itm, ind) in record.applicable_platforms.technology" :key="'teh' + ind">
                        {{ itm.name + itm.class + " (" + itm.prevalence + ")" }}
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.common_consequences">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Common consequences") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.common_consequences.consequence" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Scope") }}</th>
                          <th class="text-left">{{ $t("Impact") }}</th>
                          <th class="text-left">{{ $t("Likelihood") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.common_consequences.consequence" :key="'mi' + i">
                          <td>
                            <div v-for="(itm, ind) in index.scope" :key="'scope' + ind">
                              {{ itm }}
                            </div>
                          </td>
                          <td>
                            <div class="text-subtitle-2">
                              {{ $t("Technical Impact:") }}
                              <v-chip v-for="(itm, ind) in index.scope" :key="'impact' + ind" small label class="mr-1">
                                {{ itm }}
                              </v-chip>
                            </div>
                            <div>
                              {{ index.note }}
                            </div>
                          </td>
                          <td>{{ index.likelihood }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.likelihood_of_exploit">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Likelihood of exploit") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  {{ record.likelihood_of_exploit }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.demonstrative_examples">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Demonstrative examples") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-row dense> </v-row>
                  <div v-for="(index, i) in record.demonstrative_examples.demonstrative_example" :key="'de' + i">
                    <div>{{ $t("Example") + " " + (i + 1) + ":" }}</div>
                    <div>{{ index.title_text }}</div>
                    <div>{{ index.intro_text }}</div>
                    <div>{{ index.example_code }}</div>
                    <div>{{ index.body_text }}</div>
                    <div>{{ index.references }}</div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.observed_examples">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Observed examples") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.observed_examples.observed_example" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Reference") }}</th>
                          <th class="text-left">{{ $t("Description") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.observed_examples.observed_example" :key="'oe' + i">
                          <td>
                            <a :href="index.link">{{ index.reference }}</a>
                          </td>
                          <td>{{ index.description }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.potential_mitigations">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Potential mitigations") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 px-0 ">
                  <div v-for="(index, i) in record.potential_mitigations.mitigation" :key="'pm' + i" class="mt-3">
                    <div class="text-h3">{{ "Phase: " + index.phase }}</div>
                    <div class="text-subtitle-2">{{ "Strategy: " + index.strategy }}</div>
                    <div>{{ index.description }}</div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.weakness_ordinalities">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Weakness ordinalities") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.weakness_ordinalities.weakness_ordinality" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Ordinality") }}</th>
                          <th class="text-left">{{ $t("Description") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.weakness_ordinalities.weakness_ordinality" :key="'wo' + i">
                          <td>{{ index.ordinality }}</td>
                          <td>{{ index.description }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.detection_methods">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Detection Methods") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 px-0 ">
                  <div v-for="(index, i) in record.detection_methods.detection_method" :key="'pm' + i" class="mt-3">
                    <div class="text-h3">{{ index.method }}</div>
                    <div>{{ index.description }}</div>
                    <div class="text-subtitle-2 mt-1">{{ "Effectiveness: " + index.effectiveness }}</div>
                    <div v-if="index.effectiveness_notes">
                      <span class="text-subtitle-2 mt-1">{{ "Note: " }}</span>
                      {{ index.effectiveness_notes }}
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.notes">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Notes") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 px-0 ">
                  <div v-for="(index, i) in record.notes.note" :key="'notes' + i" class="mt-3">
                    <div class="text-h3">{{ index.type }}</div>
                    <div>{{ index.note }}</div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.taxonomy_mappings">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Taxonomy mappings") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.taxonomy_mappings.taxonomy_mapping" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("Mapped Taxonomy Name") }}</th>
                          <th class="text-left">{{ $t("Node ID") }}</th>
                          <th class="text-left">{{ $t("Fit") }}</th>
                          <th class="text-left">{{ $t("Mapped Node Name") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.taxonomy_mappings.taxonomy_mapping" :key="'wo' + i">
                          <td>{{ index.taxonomy_name }}</td>
                          <td>{{ index.entry_id }}</td>
                          <td>{{ index.mapping_fit }}</td>
                          <td>{{ index.entry_name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.related_attack_patterns">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Related attack patterns") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-simple-table v-if="record.related_attack_patterns.related_attack_pattern" dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">{{ $t("CAPEC-ID") }}</th>
                          <th class="text-left">{{ $t("Attack Pattern Name") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in record.related_attack_patterns.related_attack_pattern" :key="'wo' + i">
                          <td>{{ index.capec_id }}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.references">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("References") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 px-0 ">
                  <div v-for="(index, i) in record.references.reference" :key="'references' + i" class="mt-3">
                    <span>{{ "[" + index.external_reference_id + "] " }}</span>
                    <span>{{ index.section }}</span>
                    <v-divider />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="record.content_history">
                <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                  {{ $t("Content History") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-3 px-0 ">
                  <v-expansion-panels v-model="conts" multiple>
                    <v-expansion-panel v-if="record.content_history.submission">
                      <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                        {{ $t("Submission") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mt-3 px-0 ">
                        <v-simple-table v-if="record.content_history.submission" dense>
                          <template #default>
                            <thead>
                              <tr>
                                <th class="text-left">{{ $t("Submission Date") }}</th>
                                <th class="text-left">{{ $t("Submitter") }}</th>
                                <th class="text-left">{{ $t("Organization") }}</th>
                                <th class="text-left">{{ $t("Comment") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ record.content_history.submission.submission_date }}</td>
                                <td>{{ record.content_history.submission.submission_name }}</td>
                                <td>{{ record.content_history.submission.submission_organization }}</td>
                                <td>{{ record.content_history.submission.submission_comment }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="record.content_history.modification">
                      <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                        {{ $t("Modification") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mt-3 px-0 ">
                        <v-simple-table v-if="record.content_history.modification" dense>
                          <template #default>
                            <thead>
                              <tr>
                                <th class="text-left">{{ $t("Modification Date") }}</th>
                                <th class="text-left">{{ $t("Modifier") }}</th>
                                <th class="text-left">{{ $t("Organization") }}</th>
                                <th class="text-left">{{ $t("Comment") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(index, i) in record.content_history.modification" :key="'chm' + i">
                                <td>{{ index.modification_date }}</td>
                                <td>{{ index.modification_name }}</td>
                                <td>{{ index.modification_organization }}</td>
                                <td>{{ index.modification_comment }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="record.content_history.previous_entry_name">
                      <v-expansion-panel-header style="min-height:36px" class="text-subtitle-2 py-0 grey lighten-3">
                        {{ $t("Previous Entry Names") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mt-3 px-0 ">
                        <v-simple-table v-if="record.content_history.previous_entry_name" dense>
                          <template #default>
                            <thead>
                              <tr>
                                <th class="text-left">{{ $t("Change Date") }}</th>
                                <th class="text-left">{{ $t("Previous Entry Name") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(index, i) in record.content_history.previous_entry_name" :key="'chp' + i">
                                <td>{{ index.date }}</td>
                                <td>{{ index.text }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  props: {
    recordId: { type: String, default: "" }
  },
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      conts: [1, 2, 3],
      path: "",
      render: 1,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "CWE list",
          disabled: false,
          href: "/cwe"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    },
    recordId() {
      this.$log("recordId ", this.recordId)
      if (this.recordId) {
        this.getRecords()
      }
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.$log("recordId2 ", this.recordId)
    if (this.recordId) {
      this.getRecords()
    } else {
      this.refreshPage()
    }
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "CWE") {
        this.id = this.$route.params.pathMatch
        this.panel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        this.getRecords()
      }
    },
    getRecords() {
      let where = '?where={"id":' + this.id + "}"
      if (this.recordId) {
        where = "/" + this.recordId
      }
      axios
        .get("nvd_cwe/" + where)
        .then(response => {
          if (this.recordId) {
            this.record = response.data
          } else {
            this.record = response.data._items[0]
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
